import { IncidentReasonEnum } from 'models/device-monitoring';
import { Controller, Control, FieldErrors } from 'react-hook-form-v6';
import { DamageReasons } from 'models';
import { DamageState } from './types';

// components
import { SelectOption, SingleSelectControl, TextControl } from 'components/Controls';

type Props = {
  control: Control<DamageState>;
  errors: FieldErrors<DamageState>;
};
export const MarkDamageForm = (props: Props): JSX.Element => {
  const { control, errors } = props;

  const reasonOptions: SelectOption[] =
    Object.entries(DamageReasons)
      .filter(([value]) => value !== IncidentReasonEnum.NoReason)
      .map(([value, label]) => ({ value, label }));

  return (<>
    <Controller
      render={props => (
        <SingleSelectControl
          {...props}
          label="Reason"
          selected={ props.value }
          changeHandler={ reason => props.onChange(reason) }
          values={ reasonOptions }
        />
      )}
      name="reason"
      control={control}
      rules={{ required: true }}
    />
    <Controller
      render={props => (
        <TextControl
          {...props}
          value={props.value}
          multiline
          variant="outlined"
          rows="4"
          label="Comment"
          error={!!errors.comment}
        />
      )}
      name="comment"
      control={control}
      rules={{
        required: 'Required',
        validate: value => value.trim() !== '' || 'Введите непустую строку'
      }}
    />
  </>
  );
};

