import { RawUserSubscriptionType } from 'clients/user-management/project-health-subsciption';
import { User } from 'models/user-management';
import { SubscriptionType, UserWithSubscriptionType } from 'models/user-management/projectHealthSubscriptions';


export const normalizeUserSubscriptionsRes = (users: User[], data?: RawUserSubscriptionType[]): UserWithSubscriptionType[] => {
  if (!data) {
    return [];
  }

  const subValues = Object.values(SubscriptionType);
  return users.map((user: User): UserWithSubscriptionType => {
    const subs =
      data
        .filter(sub => sub.user_id === user.id && subValues.includes(sub.period))
        .map(sub => ({
          projectId: sub.project_id,
          ownerId: sub.owner_id,
          userId: sub.user_id,
          type: sub.period as SubscriptionType,
          subscriptionId: sub.id,
        }));

    return {
      ...user,
      ProjectSubscriptions: subs.length > 0 ? subs : []
    };
  });
};
