import { DevicesFilterFields, PositionedDeviceData } from 'models/device-management';
import { DeviceConnectivity } from 'models/connectivity/deviceConnectivity';
import { dmDevicePath } from 'routing/paths';
import { commonOptions } from 'utils/tables';
import { DeviceStatesV2 } from 'models/device-monitoring';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
import { IncidentErrorType, OwnerName, PositionName, FirmwareName } from 'components/Labels';
import { ZoneLink, SPlaceLink, LevelAndGroupLink, ProjectLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';
import { DownloadButton } from './widgets/DownloadButton';

// styles
import { ThemeProvider } from 'styles/utils';
import { getMuiTheme } from 'styles/themes';

interface Props {
  data: PositionedDeviceData[];
  total: number;
  filters: DevicesFilterFields;
  connectivity: DeviceConnectivity[];
  devicesStates: { data: DeviceStatesV2[]; isLoading: boolean };
  isAdmin: boolean;
  isFetching: boolean;
  fetchConnectivity: boolean;
}

const DevicesTable = (props: Props): JSX.Element => {
  const {
    data,
    devicesStates,
    connectivity,
    filters,
    isAdmin,
    isFetching,
    total,
    fetchConnectivity
  } = props;

  const columns: MUIDataTableColumn[] = [
    {
      name: 'deviceid',
      label: 'Device ID',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => (typeof value === 'string' && value)
          ? <Link component={ RouterLink } color="secondary" to={ dmDevicePath(value) }>{ value }</Link>
          : value,
      },
    },
    {
      name: 'position',
      label: 'Position',
      options: {
        filter: false,
        sort: false,
        customBodyRender: ([networkId, positionId, deviceId]: [string, number, string]) => {
          if (!positionId) {
            return <SPlaceLink deviceId={ deviceId } />;
          } else {
            return <PositionName network={ networkId } id={ positionId } />;
          }
        }
      }
    },
    {
      name: 'isActive',
      label: 'Activation Status',
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: 'software',
      label: 'Firmware',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: dataIndex => <FirmwareName firmwareHash={ data[dataIndex].firmware_hash } />,
      }
    },
    {
      name: 'hardware',
      label: 'Hardware',
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: 'isDamaged',
      label: 'Damaged',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'issue',
      label: 'Issue',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          const deviceStatus = devicesStates.data.find(state =>
            state.device_id.toUpperCase() === data[dataIndex].device_id.toUpperCase()
          );

          return <IncidentErrorType errorType={ deviceStatus?.incident_type } />;
        }
      }
    },
    {
      name: 'group',
      label: 'Level / Group',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) =>  {
          const device = data[dataIndex];
          if (!device || !device.group_id) {
            return (
              <Typography color="textSecondary" variant="body2">Not positioned</Typography>
            );
          }

          return (
            <LevelAndGroupLink
              groupId={ device.group_id }
              groupName={ device.group_name ?? '' }
              levelId={ device.level_id }
              levelName={ device.level_name }
            />
          );
        }
      }
    },
    {
      name: 'zone',
      label: 'Zone',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => {
          const device = data[dataIndex];
          if (!device || !device.zone_id) {
            return (
              <Typography color="textSecondary" variant="body2">Not positioned</Typography>
            );
          }
          return (
            <ZoneLink zoneId={ device.zone_id }/>
          );
        },
      },
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => (
          <ProjectLink
            newWindow
            projectId={ data[dataIndex].project_id }
          />
        )
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        filter: false,
        sort: false,
        display: isAdmin ? 'true' : 'excluded',
        customBodyRenderLite: (dataIndex: number) => <OwnerName ownerId={ data[dataIndex].owner_id } />
      }
    },
    {
      name: 'connectivity',
      label: 'Connectivity',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (deviceConnectivity) => fetchConnectivity
          ? <LoaderSvg />
          : (deviceConnectivity || '-'),
      }
    },
  ];
  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    count: total,
    customToolbar: () => {
      return (
        <DownloadButton filter={ filters } />
      );
    },
    customFooter: (count) => <PaginationFooter
      count={count}
    />
  };

  return (
    <ThemeProvider theme={ getMuiTheme() }>
      <TableLoadingLayout isLoading={ isFetching || devicesStates.isLoading } >
        <MUIDataTable
          title={ null }
          data={ data.map(el => {
            const devicesConnectivity = connectivity.find((device: DeviceConnectivity) => {
              return device.device_id.toUpperCase() === el.device_id.toUpperCase();
            });

            return {
              deviceid: el.device_id,
              position: [el.network_id, el.position_id, el.device_id],
              isActive: el.activation_status ? 'Active' : 'Not active',
              hardware: el.hardware_type || '-',
              isDamaged: el.damaged_status ? 'Yes' : 'No',
              connectivity: devicesConnectivity ? `${devicesConnectivity.connectivity} %` : '-',
            };
          }) }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </ThemeProvider>
  );
};
export default DevicesTable;
