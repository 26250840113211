import { useConnectivityOverallHistory } from 'hooks/connectivity';

// components
import CardContent from '@mui/material/CardContent';
import { BlockLoader } from 'components/Loaders';
import { initalChartData } from 'components/Charts/ChartsOptions/CommonOptions';
import { ConnectivityChart } from 'components/Charts';
// styles
import useStyles from '../HomeStyles';

const ConnectivityCard = (): JSX.Element => {
  const classes = useStyles();
  const { isLoading, data } = useConnectivityOverallHistory({});
  return (
    <CardContent className={ classes.chart }>
      { isLoading ? <BlockLoader/> : (
        <ConnectivityChart
          chartData={ initalChartData() }
          connectivity={ data }
        />
      ) }
    </CardContent>
  );
};

export default ConnectivityCard;