import React from 'react';
import { useAuthUserSelector } from 'hooks';

// components
import { OwnersSingleSelectControl, TextControl, ZoneSelectControl } from 'components/Controls';

// styles
import useStyles from 'styles/filters';

export interface FilterState {
  owner?: number;
  zones: number[];
  name?: string;
  host?: string;
  vhost?: string;
  exchange?: string;
  queue?: string;
}

interface Props {
  state: FilterState;
  setState: (filters: FilterState) => void;
}

const Component = ({ state, setState }: Props): JSX.Element => {
  const classes = useStyles();
  const { isAdmin } = useAuthUserSelector();

  return (
    <form className={ classes.gridRoot } autoComplete="off">
      { !isAdmin ? '' :
        <OwnersSingleSelectControl
          isClearable
          selected={ state.owner }
          changeHandler={ (value?: number) => setState({ ...state, owner: value }) }
        />
      }
      <ZoneSelectControl
        isMulti
        isClearable
        selected={ state.zones }
        onChange={ (value: number[]) => setState({ ...state, zones: value }) }
        filter={ zone => !isAdmin || !state.owner || zone.owner_id ===  state.owner }
      />
      <TextControl
        name="name"
        label="Endpoint name"
        value={ state.name ? state.name : '' }
        onChange={ (event: React.ChangeEvent<{ value: string }>) => setState({ ...state, name: event.target.value }) }
      />
      <TextControl
        name="host"
        label="Host"
        value={ state.host ? state.host : '' }
        onChange={ (event: React.ChangeEvent<{ value: string }>) => setState({ ...state, host: event.target.value }) }
      />
      <TextControl
        name="vhost"
        label="vHost"
        value={ state.vhost ? state.vhost : '' }
        onChange={ (event: React.ChangeEvent<{ value: string }>) => setState({ ...state, vhost: event.target.value }) }
      />
      <TextControl
        name="exchange"
        label="Exchange"
        value={ state.exchange ? state.exchange : '' }
        onChange={ (event: React.ChangeEvent<{ value: string }>) => setState({ ...state, exchange: event.target.value }) }
      />
      <TextControl
        name="queue"
        label="Queue"
        value={ state.queue ? state.queue : '' }
        onChange={ (event: React.ChangeEvent<{ value: string }>) => setState({ ...state, queue: event.target.value }) }
      />
    </form>
  );
};

export default Component;
