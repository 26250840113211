import { SubscriptionType } from 'models/user-management/projectHealthSubscriptions';
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';
import { useStyles } from '../styles';

type Props =
  Partial<Omit<CommonProps<SubscriptionType>, 'options'>>
  & (SingleProps<SubscriptionType> | MultiProps<SubscriptionType>)


export const SubscriptionTypeSelectControl = (props: Props) => {
  const classes = useStyles();
  const subscriptionOptions: Option<SubscriptionType>[] = [
    { label: 'Weekly report', value: SubscriptionType.Weekly },
    { label: 'Daily report', value: SubscriptionType.Daily },
    { label: 'Station status alerts', value: SubscriptionType.Station },
    { label: 'DMS status alert', value: SubscriptionType.DMS },
  ];

  return (
    <SelectControl
      name="subscriptionType"
      label=""
      { ...props }
      options={ subscriptionOptions }
      isClearable
      ControlProps={ {
        className: classes.noMarginForm,
      } }
    />
  );
};
