import React from 'react';
import { ShipmentFilters } from 'models/provisioning';
import { ShipmentState } from 'models/provisioning';

// components
import Grid from '@mui/material/Grid';
import { OwnersSingleSelectControl, CustomIdSelect } from 'components/Controls';
import { ShipmentStatusSelector } from './Controls/ShipmentStatusSelector';

// styles
import useStyles from 'styles/filters';

interface Props {
  fetching: boolean;
  isAdmin: boolean;
  state: ShipmentFilters;
  setState: (filters: ShipmentFilters) => void;
}

const ShipmentTableFilter = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { isAdmin, state, setState } = props;

  const selectRecipient = (id?: number) => setState({ ...state, recipient_id: Number.isInteger(id) ? id : undefined });
  const selectSender = (id?: number) => setState({ ...state, shipper_id: Number.isInteger(id) ? id : undefined });
  const selectStatus = (status?: keyof typeof ShipmentState) => setState({ ...state, shipment_status: status ? [status] : [] });
  const selectShipmentIds = (shipment_id: string[]) => setState({ ...state, shipment_id });

  return (
    <form className={ classes.gridRoot }>
      <Grid>
        <ShipmentStatusSelector
          isClearable
          selected={ state.shipment_status ? state.shipment_status[0] : undefined }
          selectStatus={ selectStatus }
        />
      </Grid>
      { isAdmin &&
        <>
          <Grid>
            <OwnersSingleSelectControl
              isClearable
              changeHandler={ selectSender }
              selected={ state.shipper_id }
              label={ 'Sender' }
            />
          </Grid>
          <Grid>
            <OwnersSingleSelectControl
              isClearable
              changeHandler={ selectRecipient }
              selected={ state.recipient_id }
              label={ 'Recipient' }
            />
          </Grid>
          <Grid>
            <CustomIdSelect
              changeHandler={ selectShipmentIds }
              selected={ state.shipment_id }
              name={ 'Shipment' }
              type="string"
              mapInputValue={ value => value.replace(/[^A-Za-z0-9-]+/g, '') }
            />
          </Grid>
        </>
      }
    </form >
  );
};

export default ShipmentTableFilter;
