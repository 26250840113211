import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuthUserSelector } from 'hooks';
import {
  Device,
  DeviceColdData,
  ExistingPosition
} from 'models/device-management';
import { isAllowedToUpdateDevice } from 'utils/permissions';
import { useForm } from 'react-hook-form-v6';
import { isDeviceVirtual } from 'utils/models';
import { RootState } from 'reducers';
import { dispatchAsync } from 'utils/store';
import { useFirmwaresDictionarySelector } from 'hooks/firmware-management';
import { updateDevice } from 'actions/device-management/devices';
import { DoFetchProvisioningDeviceById } from 'actions/provisioning';
import { UpdateDeviceFirmware } from 'actions/firmware-management';
import { Firmware } from 'models/firmware-management';

import { DeviceFormValues } from './types';

// components
import { FieldSkeleton } from 'components/Skeleton';
import { Paper } from '@mui/material';
import DeviceGeneralInfo from '../DeviceGeneralInfo';
import DeviceBusinessInfo from '../DeviceBusinessInfo';
import DeviceIncidents from '../DeviceIncidents';
import DeviceMonitoring from '../DeviceMonitoring';
import DeviceProvisioningInfo from '../DeviceProvisioningInfo';
import { VirtualDeviceSettings } from '../VirtualDeviceSettings';
import { DeviceBlockHeader } from './DeviceBlockHeader';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

export interface DeviceBlockProps {
  dependent?: boolean;
  device?: Device & DeviceColdData;
  deviceId: Device['device_id'];
  positionId?: ExistingPosition['id'];
  onDevicePositionRelationUpdate?: () => void;
}

const getDefaultValues = (device?: Device): DeviceFormValues => {
  return ({
    ...device,
    activation_status: device?.activation_status ?? false,
    damaged_status: device?.damaged_status ?? false,
    hardware_type: device?.hardware_type || null,
  });
};

const DeviceBlock: React.FC<DeviceBlockProps> = ({
  dependent = false,
  device,
  deviceId,
  onDevicePositionRelationUpdate,
}) => {
  const dispatch = useDispatch();
  const allowedToUpdateDevice = useSelector((state: RootState) => isAllowedToUpdateDevice(state.user.data));
  const { isAdmin } = useAuthUserSelector();
  const infoCss = useInfoBlockStyles();
  const { firmwares } = useFirmwaresDictionarySelector();
  const deviceVirtual = deviceId ? isDeviceVirtual(deviceId) : undefined;

  // get provisioning info
  const deviceProvisioning = useSelector((state: RootState) => state.provisioning.devices);
  const currentDevice = deviceId ?
    deviceProvisioning.devices.find(device => device.device_id.toUpperCase() === deviceId.toUpperCase())
    : null;

  useEffect(() => {
    if (!currentDevice && deviceId && !deviceVirtual) {
      dispatch(DoFetchProvisioningDeviceById(deviceId));
    }
  }, [currentDevice, dispatch, deviceId, deviceVirtual]);

  const defaultValues = getDefaultValues(device);
  const { getValues, control, reset, formState: { isDirty }, watch, setValue } = useForm({
    defaultValues,
  });
  useEffect(() => { reset(getDefaultValues(device)); }, [device, reset]);

  const handleSubmit = async (values: DeviceFormValues) => {
    const deviceUpdateProps: DeviceFormValues = {
      ...device,
      ...values,
      hardware_type: values.hardware_type || null
    };

    // we don't support resetting `firmware_hash`, so we check `values.firmware_hash` to be filled
    // for virtual devices @TODO
    if (
      defaultValues.firmware_hash &&
      values.firmware_hash &&
      values.firmware_hash !== defaultValues.firmware_hash
    ) {
      const firmware = firmwares.find(f => f.hash === values.firmware_hash);

      await dispatchAsync(
        dispatch,
        UpdateDeviceFirmware({
          deviceId: device?.device_id as string,
          firmwareHash: values.firmware_hash,
          firmware: firmware as Firmware,
        }),
      );
    }

    return dispatchAsync(dispatch, updateDevice({
      id: device?.device_id as string,
      props: deviceUpdateProps,
    }));
  };

  if (!device || !deviceId) {
    return <FieldSkeleton className={ infoCss.field } />;
  }

  return (<Paper className={ infoCss.root }>
    <>
      <DeviceBlockHeader
        dependent={dependent}
        deviceId={deviceId}
        reset={ reset }
        dirty={ isDirty }
        allowedToUpdateDevice={allowedToUpdateDevice}
        isSubmitting={ false }
        onSave={ async () => {
          const v = await getValues();
          await handleSubmit(v);
        }}
      />
      <DeviceGeneralInfo
        control={control}
        watch={ watch }
        setValue={ setValue }
        device={ device }
        onDevicePositionRelationUpdate={() => {
          onDevicePositionRelationUpdate && onDevicePositionRelationUpdate();
          if (defaultValues.activation_status) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            handleSubmit({ ...defaultValues, activation_status: false });
          }
        }}
      />
    </>

    { device?.device_id && deviceVirtual && (
      <VirtualDeviceSettings
        allowedToUpdateDevice={ allowedToUpdateDevice }
        device={ device }
        onActivateDevice={() => {
          return handleSubmit({ ...defaultValues, activation_status: true });
        }}
      />
    ) }

    <DeviceMonitoring device={ device } />

    <DeviceIncidents device={ device } />

    { (!deviceVirtual && deviceProvisioning.devices[0]) && (
      <DeviceBusinessInfo
        device={ device }
        provisioningDevices={ deviceProvisioning.devices }
        // idPrefix={ combineIds(idPrefix, 'business') }
      />
    ) }

    { device?.device_id && !deviceVirtual && isAdmin && (
      <DeviceProvisioningInfo currentDevice={ currentDevice } />
    ) }
  </Paper>
  );
};

export default DeviceBlock;
