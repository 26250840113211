/* eslint-disable no-console */
import { uniqWith } from 'lodash';
import React from 'react';
import { Controller, Control, UseFormMethods } from 'react-hook-form-v6';
import { Device, SoftType } from 'models/device-management';
import { isAllowedToUpdateDevice } from 'utils/permissions';
import { useAuthUserSelector, useDialog } from 'hooks';
import { isDeviceVirtual } from 'utils/models';
import { useFirmwaresDictionarySelector, getVirtualFirmwares } from 'hooks/firmware-management';
import { DeviceFormValues } from '../DeviceBlock/types';
import { Firmware } from 'models/firmware-management';

// components
import { OwnersSingleSelectControl } from 'components/Controls';
import { SelectControl } from 'components/Controls/Select';
import { Accordion, AccordionDetails, FormControlLabel, FormGroup, Grid, TextField, Switch, Box } from '@mui/material';
import { FieldSkeleton } from 'components/Skeleton';

import DeviceOperations from './DeviceOperations';
import { MarkDeviceDamageDialogComponent } from 'components/DeviceManagement';
import { SelectOption } from 'components/Controls';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface Props {
  control: Control<Partial<DeviceFormValues>>;
  device?: Device;
  onDevicePositionRelationUpdate?: () => void;
  setValue: UseFormMethods['setValue'];
  watch: UseFormMethods['watch'];
}

function getFirmwareHashOptions(
  allFirmwares: Firmware[],
  currentFirmwareHash?: string,
  currentSoftType?: SoftType,
): SelectOption<string | undefined>[] {
  const options: SelectOption<string | undefined>[] = [];

  const currentFirmware = currentFirmwareHash
    ? allFirmwares.find(firmware => firmware.hash === currentFirmwareHash)
    : undefined;

  const firmwaresToShow = uniqWith([
    // if currentFirmware's name and version are equal to one of allowed firmwares
    // but currentFirmware's hash is different, we need to show currentFirmware
    // since otherwise there'll be no option matching the field value so field will be broken
    ...(currentFirmware ? [currentFirmware] : []),
    ...getVirtualFirmwares(allFirmwares),
  ], (a, b) => a.name === b.name && a.version === b.version);

  if (!currentFirmware && currentSoftType) {
    options.push({ value: undefined, label: currentSoftType });
  }

  return [
    ...firmwaresToShow.map(f => ({ value: f.hash, label: `${f.name}/${f.version}` })),
    ...options
  ];
}

const DeviceGeneralInfo: React.FC<Props> = ({ control, setValue, watch, device, onDevicePositionRelationUpdate }) => {
  const firmwareHash = watch('firmware_hash');
  const { firmwares } = useFirmwaresDictionarySelector();
  const { isAdmin, data } = useAuthUserSelector();
  const damageDialog = useDialog();
  const allowedToUpdateDevice = isAllowedToUpdateDevice(data);

  const infoCss = useInfoBlockStyles();

  if (!device) {
    return <FieldSkeleton className={ infoCss.field } />;
  }

  return (
    <Accordion expanded>
      <AccordionDetails>
        <FormGroup className={ infoCss.fields }>
          <Box display="grid" pb={1}>
            <Controller
              render={props => <TextField
                {...props}
                label="Hardware type"
                disabled={ !isAdmin }
              />}
              name="hardware_type"
              control={control}
            />
          </Box>
          <Box display="grid" pb={1}>
            <Controller
              render={props => (
                <SelectControl
                  {...props}
                  label="Firmware"
                  selected={ firmwareHash }
                  options={ getFirmwareHashOptions(firmwares, firmwareHash, device.soft_type) }
                  isDisabled={ !allowedToUpdateDevice || !isDeviceVirtual(device.device_id) }
                />
              )}
              name="firmware_hash"
              control={control}
              rules={{ required: true }}
            />
          </Box>
          <Box display="grid" pb={1}>
            <Controller
              render={props => <TextField
                {...props}
                disabled
                label="High level protocol"
              />}
              name="high_level_protocol_version"
              control={control}
            />
          </Box>
          <Grid container>
            <Grid item xs={ 12 } md={ 6 }>
              <Controller
                render={(props) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.value}
                        onChange={(e) => { props.onChange(e.target.checked); }}
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label="Active"
                    style={{ marginLeft: 0 }}
                    disabled={ !allowedToUpdateDevice }
                  />
                )}
                name="activation_status"
                control={control}
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <Controller
                render={(props) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.value}
                        onChange={ (e) => !device.damaged_status ? damageDialog.open() : props.onChange(e.target.checked) }
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label="Damaged"
                    disabled={ !allowedToUpdateDevice }
                  />
                )}
                name="damaged_status"
                control={control}
              />
            </Grid>
          </Grid>
          {isAdmin && <Controller
            render={props =>
              <OwnersSingleSelectControl
                {...props}
                selected={props.value}
                changeHandler={props.onChange}
                isClearable={false}
              />}
            name="owner_id"
            control={control}
          />}
          <br />
          <DeviceOperations
            device={ device }
            onDevicePositionRelationUpdate={ onDevicePositionRelationUpdate }
          />
        </FormGroup>
      </AccordionDetails>
      { damageDialog.isMounted && <MarkDeviceDamageDialogComponent
        device={ device as Device }
        isOpen={ damageDialog.isOpen }
        onClose={ () => {
          setValue('damaged_status', false);
          damageDialog.close();
        } }
        onCloseEnd={ damageDialog.unmount }
      /> }
    </Accordion>
  );
};

export default DeviceGeneralInfo;
