import { useMutation } from 'react-query';
import { useFormActionNotifier } from 'hooks/form';
import { PositionGroupUpdateParams } from 'models/device-management';
import { updateGroup } from 'clients/device-management';
import { getResponseData } from 'utils/clients';
import { clearQueryCache } from 'utils/react-query';


interface Param {
  onError?: (e: Error) => void;
  onSuccess?: () => void;
}

export function useGroupUpdateMutation(param: Param) {
  const notifier = useFormActionNotifier();

  const mutation = useMutation({
    mutationFn: async (update: PositionGroupUpdateParams) => {
      const res = await updateGroup(update);
      res.data = res.data || { id: update.id };

      return getResponseData(res);
    },
    onError: (e) => {
      notifier.notifyError(`Error while update group: ${ (e as Error).message }`);
      param.onError?.(e as Error);
    },
    onSuccess: () => {
      notifier.notifySuccess('Group has been updated successfully');
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      clearQueryCache('deviceManagement');
      param.onSuccess?.();
    },

  });

  return {
    isLoading: mutation.isLoading,
    updateGroup: mutation.mutate
  };
}