import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Dispatch } from 'redux';
import { RootState } from 'reducers';
import { DoGetStatisticMainPage } from 'actions/main-statistic';

// components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { HTTPCallerHistoryChart, RMQHistoryChart, RawMessagesChart, RawOnlineChart } from 'components/Charts';
import { BlockLoader } from 'components/Loaders';
import ConnectivityCard from './widgets/ConnectivityCard';

// styles
import contentStyles from 'styles';
import useStyles from './HomeStyles';

const mapState = (state: RootState) => ({
  stat: state.mainStatistic
});

const mapDispatch = (dispatch: Dispatch) => ({
  getStat: (quiet?: boolean) => {
    dispatch(DoGetStatisticMainPage(quiet));
  }
});

const connector = connect(
  mapState,
  mapDispatch
);

const Component = (props: ConnectedProps<typeof connector>): JSX.Element => {
  const classes = {
    ...contentStyles(),
    ...useStyles(),
  };

  const { getStat } = props;

  useEffect(() => {
    getStat();
    const fetchQuiet = () => getStat(true);
    window.addEventListener('focus', fetchQuiet);
    return () => {
      window.removeEventListener('focus', fetchQuiet);
    };
  }, [getStat]);

  return (
    <>
      <Helmet>
        <title>Nwave Console</title>
      </Helmet>

      <Box ml={ -2 } mr={ -2 } display="flex" mt={ 1 }>
        <Grid container spacing={ 1 } alignContent="flex-start">
          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ classes.rawChart }>
                  <ConnectivityCard />
                </Box>
              </div>
            </Card>
          </Grid>

          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ classes.rawChart }>
                  <CardContent className={ classes.chart }>
                    { props.stat.rawStatistic.fetching ? <BlockLoader /> :
                      <RawMessagesChart data={ props.stat.rawStatistic.data } />
                    }
                  </CardContent>
                </Box>
              </div>
            </Card>
          </Grid>

          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ classes.rawChart }>
                  <CardContent className={ classes.chart }>
                    { props.stat.rmqSender.fetching ? <BlockLoader /> :
                      <RMQHistoryChart history={ props.stat.rmqSender.data } />
                    }
                  </CardContent>
                </Box>
              </div>
            </Card>
          </Grid>

          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ classes.rawChart }>
                  <CardContent className={ classes.chart }>
                    { props.stat.rmqSender.fetching ? <BlockLoader /> :
                      <HTTPCallerHistoryChart history={ props.stat.httpCalls.data } />
                    }
                  </CardContent>
                </Box>
              </div>
            </Card>
          </Grid>

          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ classes.rawChart }>
                  <CardContent className={ classes.chart }>
                    { props.stat.rawOnline.fetching ? <BlockLoader /> :
                      <RawOnlineChart data={ props.stat.rawOnline.data } />
                    }
                  </CardContent>
                </Box>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const Home: React.FC = connector(Component);
