import { useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useLevelsSelector } from 'hooks/device-management/levels';
import { RootState } from 'reducers';
import { Group } from 'models/device-management';
import { useZones, useGroups } from 'hooks/device-management';
import { fetchAvgConnectivityPerPositionGroup } from 'actions/device-connectivity';
import { dmGroupsCreatePath } from 'routing/paths';

// components
import { GroupsTable, GroupsFilter } from 'components/DeviceManagement';
import AddButton from 'components/Buttons/AddButton';
import { useGroupParams } from './useGroupParams';

// styles
import useStyles from 'styles/page';

const Groups = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // meta data
  const { isAdmin } = useSelector((state: RootState) => state.user);
  const { zones } = useZones({ params: {} });
  const { findLevelById, isLoading: levelsLoading } = useLevelsSelector({
    params: { limit: 2_000 },
    withFindById: true,
  });
  const connectivityByGroupId = useSelector((state: RootState) => ({
    dictionary: state.connectivity.avgByPositionGroupId,
    loading: state.connectivity.avgByPositionGroupIdFetching,
  }), shallowEqual);

  const { filters, applyFilters, limit, page } = useGroupParams();

  const { groups, isLoading: isFetching } = useGroups({ params: { ...filters } });

  // comment
  const ids = (groups as Required<Group>[]).map(group => group.id);
  useEffect(() => {
    while (ids.length) {
      const groupsIds = ids.splice(0, 300);
      dispatch(fetchAvgConnectivityPerPositionGroup({ groups: groupsIds }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groups]);

  return (
    <>
      <Helmet>
        <title>Groups</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <GroupsFilter
          applyFilters={ filter => { applyFilters(filter); }}
          filters={filters}
          isFetching={isFetching}
        />
      </div>
      <div className={ classes.table }>
        <GroupsTable
          isLoading={ isFetching }
          positionGroups={ groups }
          connectivityByGroupId={ connectivityByGroupId }
          zones={ zones }
          findLevelById={ findLevelById }
          levelsLoading={ levelsLoading }
          isAdmin={ isAdmin }
          containsAllFields
          limit={ limit }
          page={ page }
        />
      </div>
      <AddButton label="Create group" LinkTo={ dmGroupsCreatePath } />
    </>
  );
};

export default Groups;
