import { ProjectsFilters } from 'models/projects';

// components
import Grid from '@mui/material/Grid';
import { OwnersSingleSelectControl, ProjectSelectControl } from 'components/Controls';

// styles
import contentStyles from 'styles';
import useStyles from 'styles/filters';

interface Props {
  isAdmin: boolean;
  state: ProjectsFilters;
  setState: (filters: ProjectsFilters) => void;
}

export const ProjectsFilter = ({ isAdmin, state, setState }: Props): JSX.Element => {
  const classes = {
    ...contentStyles(),
    ...useStyles()
  };

  const handleSelectOwner = (owner: number | undefined) => setState({ ...state, owner });
  const handleSelectProjects = (projects: ProjectsFilters['projects']) => setState({ ...state, projects });

  return (
    <form className={ classes.gridRoot } autoComplete="off">
      { isAdmin &&
        <Grid>
          <OwnersSingleSelectControl
            isClearable
            changeHandler={ handleSelectOwner }
            selected={ state.owner }
          />
        </Grid>
      }
      <Grid>
        <ProjectSelectControl
          isMulti
          isClearable
          selected={ state.projects || [] }
          onChange={ handleSelectProjects }
          filter={ project => !state.owner || project.owner_id === state.owner }
        />
      </Grid>
    </form>
  );
};
