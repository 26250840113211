import { staticEnv } from 'env';
import { useHistory, useLocation } from 'react-router-dom';
import { Device, DeviceColdData, ExistingPosition } from 'models/device-management';
import { dmPositionsPath } from 'routing/paths';
import { isNil } from 'utils/models';
import { parseUrlParams, UrlItems } from 'utils/routing/query';
import { useGroups } from 'hooks/device-management';
import { showParkingAnomaly } from 'helpers';
import { MainEntity } from './types';
import { getPageTitle } from './utils';
import { isPositionId } from 'utils/models';

// components
import { Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import DeviceBinding from './widgets/DeviceBinding';

import DeviceBlock from './widgets/DeviceBlock';
import PositionBlock from './widgets/PositionBlock';
import { PositionParkingAnomaly } from 'components/ParkingAnomaly';

// styles
import useProjectStyles from 'styles';
import useStyles from './styles';

export interface Props {
  mainEntity?: MainEntity;
  onDevicePositionRelationUpdate?: () => void;

  device?: (Device & DeviceColdData) | null;
  deviceId?: Device['device_id'] | null;
  deviceLoading: boolean;

  position?: ExistingPosition | null;
  positionId?: ExistingPosition['id'] | null;
  positionLoading: boolean;
}

type ContentParams = {
  returnUrl?: string;
};

const buildParams = (params: UrlItems): ContentParams => ({
  returnUrl: params.returnUrl && decodeURIComponent(params.returnUrl),
});

const CouplePage: React.FC<Props> = ({
  mainEntity = MainEntity.DEVICE,
  onDevicePositionRelationUpdate,

  device,
  deviceId,
  deviceLoading,

  position,
  positionId,
  positionLoading,
}) => {
  const history = useHistory();
  const location = useLocation();
  const projectCss = useProjectStyles();
  const layoutCss = useStyles();

  const { groups } = useGroups({ params: { groups: position ? [position.group_id]: [] }, enabled: !!position });
  const type = groups && groups[0] && groups[0].type;
  const isShowParkingAnomaly = type ? showParkingAnomaly(type) : false;

  if (
    device === null && !deviceLoading &&
    position === null && !positionLoading
  ) {
    return (
      <Typography variant="h4" className={ projectCss.pageNotImplemented }>
        You have requested the page of nonexistent device or position.
      </Typography>
    );
  }

  const handlePositionDelete = () => {
    if (mainEntity !== MainEntity.POSITION) {
      onDevicePositionRelationUpdate?.();
      return;
    }

    const { returnUrl = dmPositionsPath() } = parseUrlParams(location.hash, buildParams);
    history.push(returnUrl);
  };

  const gridDirection = mainEntity === MainEntity.DEVICE ? 'row' : 'row-reverse';

  return (
    <>
      <Helmet>
        <title>{ getPageTitle(
          mainEntity,
          (deviceLoading || !device) ? `${deviceId}` : device.device_id,
          (positionLoading || !position) ? `${positionId}` : (position.custom_id ?? position.network_id ?? `${positionId}`),
        ) }</title>
      </Helmet>

      <Grid container spacing={ 2 } direction={ gridDirection }>
        <Grid className={ layoutCss.block } item>
          {deviceId === null && isPositionId(positionId) ?
            <DeviceBinding positionId={ positionId } onSuccess={ onDevicePositionRelationUpdate } />
            : <DeviceBlock
              dependent={ mainEntity !== MainEntity.DEVICE }
              device={ device || undefined }
              deviceId={ deviceId || '' }
              positionId={ isNil(positionId) ? undefined : positionId }
              onDevicePositionRelationUpdate={ onDevicePositionRelationUpdate }
            />
          }
        </Grid>

        <Grid className={ layoutCss.block } item>
          <PositionBlock
            dependent={ mainEntity !== MainEntity.POSITION }
            device={ device || undefined }
            position={ position || undefined }
            positionId={ positionId }
            idPrefix="position"
            onDevicePositionRelationUpdate={ onDevicePositionRelationUpdate }
            onPositionDelete={ handlePositionDelete }
          />
          {staticEnv.IS_PARKING_ANOMALY_ON && (isShowParkingAnomaly && <PositionParkingAnomaly positionId={ positionId ?? undefined } />) }
        </Grid>

      </Grid>
    </>
  );
};

export default CouplePage;
