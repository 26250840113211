import { dmDevicePath } from 'routing/paths';

// components
import { Button as MuiButton, Link, Box } from '@mui/material';
import { Button } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';
import { CustomLink } from 'components/Links';

// styles
import { ThemeProvider } from 'styles/utils';
import { successTheme } from 'styles/themes';

type HeaderProps = {
  dependent: boolean;
  deviceId: string;
  dirty: boolean;
  reset: () => void;
  isSubmitting: boolean;
  allowedToUpdateDevice: boolean;
  onSave: () => void;
};

export const DeviceBlockHeader = ({
  dependent,
  deviceId,
  dirty,
  allowedToUpdateDevice,
  reset,
  isSubmitting,
  onSave
}: HeaderProps) => {
  return (
    <BlockToolbar>
      <BlockTitle>
        <Box padding="0px 16px">
          {/* replace with `props.renderTitle()` if more customization is needed */ }
          { dependent ? 'Bound device' : 'Device' }{ ' ' }
          { deviceId && (
            <Link
              activeAsText
              color="secondary"
              component={ CustomLink }
              to={ dmDevicePath(deviceId) }
            >
              { deviceId }
            </Link>
          )}
        </Box>
      </BlockTitle>

      { allowedToUpdateDevice && dirty && (
        <MuiButton color="inherit" onClick={ reset }>
            Cancel
        </MuiButton>
      ) }

      { allowedToUpdateDevice && (
        <span>
          {/* disabled buttons don't fire events, so we need a wrapper like <span> */ }
          <ThemeProvider theme={ successTheme }>
            <Button
              color="primary"
              disabled={ !dirty }
              pending={ isSubmitting }
              onClick={ onSave }
            >
                Save
            </Button>
          </ThemeProvider>
        </span>
      ) }
    </BlockToolbar>
  );
};