import { IncidentReasonEnum } from 'models/device-monitoring';
import { useFormActionLoader } from 'hooks/form';
import { useForm } from 'react-hook-form-v6';
import { CreateDamagedIncident } from 'actions/http-incidents';
import { Device } from 'models/device-management';

// components
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { InfoDialog, MuiDialog } from 'components/Dialogs';
import { MarkDamageForm } from './form';

interface Props {
  device: Device;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const MarkDeviceDamageDialogComponent = ({ device, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();

  const form = useForm({
    defaultValues: {
      reason: IncidentReasonEnum.BrokenCasing,
      comment: '',
    },
  });

  if (!device.position_id) {
    return (
      <InfoDialog
        name="mark-device-damage"
        label="Can not mark device damage"
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
      >
        The device <b>{ device.device_id }</b> can not be mark damage.<br/>
        First place the device into position
      </InfoDialog>
    );
  }

  const handleConfirm = async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      return false;
    }
    const values = form.getValues();
    doAction({
      action: CreateDamagedIncident(device.device_id, values.reason, values.comment),
      onSuccess: onClose,
    });
  };

  return (
    <MuiDialog
      open={ isOpen }
      onClose={ onClose }
      onExited={ onCloseEnd }
      maxWidth={ 'sm' }
    >
      <DialogTitle>Please select the device damaging reason</DialogTitle>
      <DialogContent style={{ overflow: 'initial' }}>
        <MarkDamageForm
          control={form.control}
          errors={form.errors}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={ onClose } autoFocus>
          Cancel
        </Button>
        <Button color="primary" onClick={ handleConfirm }>
          Mark as damaged
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
