import moment from 'moment';
import { useAuthUserSelector } from 'hooks';
import { Statistic, Zone } from 'models/device-management';
import { ZoneConnectivity } from 'models/connectivity/zoneConnectivity';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { OwnerName } from 'components/Labels';
import { DmLabels } from 'components/DmLabels';
import { ProjectLink, SPlaceLink, ZoneLink } from 'components/Links';
import BoundedDevices from './BoundedDevices';
import { LoaderSvg } from 'components/Loaders';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';

// styles
import { ThemeProvider } from 'styles/utils';
import { truncatedCellsMuiTableTheme } from 'styles/themes';

export interface Props {
  devicesByZone?: Statistic['by_zones'];
  connectivity?: ZoneConnectivity[];
  isLoading?: boolean;
  statFetch?: boolean;
  data: Zone[];
  total: number;
}

const ZonesTable = ({ data, total, devicesByZone, connectivity, isLoading, statFetch }: Props): JSX.Element => {
  const { isAdmin } = useAuthUserSelector();
  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Zone ID',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'zone',
      label: 'Zone Name',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => (
          <ZoneLink zoneId={ data[dataIndex].id }/>
        ),
      },
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => (
          <ProjectLink
            newWindow
            projectId={ data[dataIndex].project_id }
          />
        )
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        filter: false,
        sort: false,
        display: isAdmin ? 'true' : 'excluded',
        customBodyRenderLite: (dataIndex: number) => <OwnerName ownerId={ data[dataIndex].owner_id }/>
      }
    },
    {
      name: 'live',
      label: 'Live',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => data[dataIndex].live_status ? 'Yes' : 'No',
      }
    },
    {
      name: 'boundDevicesNumber',
      label: 'Bound Devices Number',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          return statFetch ? <LoaderSvg/> : (
            <BoundedDevices
              id={ data[dataIndex].id }
              devicesByZone={ devicesByZone }/>
          );
        }
      }
    },
    {
      name: 'connectivity',
      label: 'Connectivity',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          if (statFetch) {
            return <LoaderSvg/>;
          }
          const zone = data[dataIndex];
          const connectivityByZone = connectivity?.find(connectivity => connectivity.zone_id === zone.id);
          return connectivityByZone?.connectivity ? `${ connectivityByZone.connectivity } %` : '-';
        }
      }
    },
    {
      name: 'labels',
      label: 'Labels',
      options: {
        customBodyRenderLite: dataIndex => <DmLabels labels={ data[dataIndex].labels ?? [] }/>,
      }
    },
    {
      name: 'groupsNumber',
      label: 'Groups Number',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      }
    },
    {
      name: 'positionsNumber',
      label: 'Positions Number',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      }
    },
    {
      name: 'damagedDevices',
      label: 'Damaged Devices/Silent devices',
      options: {
        filter: false,
        sort: false,
        display: 'excluded'
      }
    },
    {
      name: 'creationTime',
      label: 'Creation time',
      options: {
        filter: false,
        sort: false,
        display: 'false',
        customBodyRenderLite: dataIndex => {
          const zone = data[dataIndex];

          return zone.creation_date ? moment(zone.creation_date).format('YYYY-MM-DD') : '-';
        }
      }
    },
    {
      name: 'bind',
      label: ' ',
      options: {
        viewColumns: false,
        customBodyRenderLite: dataIndex => <SPlaceLink zoneId={ data[dataIndex].id }/>,
      },
    },
  ];
  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    customFooter: () => {
      return (
        <PaginationFooter
          count={ total }
        />
      );
    },
  };
  return (
    <ThemeProvider theme={ truncatedCellsMuiTableTheme(data.length) }>
      <TableLoadingLayout isLoading={ isLoading }>
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </ThemeProvider>
  );
};

export default ZonesTable;
