import React from 'react';
import { UserGroup } from 'models/user-management';
import { useUserGroupsDictionarySelector } from 'hooks/user-managment';

// components
import { SingleSelectControl, SingleSelectProps, SelectOption } from 'components/Controls/Select';

export type Props = Partial<Omit<SingleSelectProps, 'changeHandler' | 'selected' | 'filter'>> & {
  selected?: number;
  changeHandler: (value?: number) => void;
  filter?: (owner: UserGroup) => boolean;
  label?: string;
  isClearable?: boolean;
};

const Component = (props: Props): JSX.Element => {
  const { selected, label } = props;
  const { isLoading, userGroups } = useUserGroupsDictionarySelector();
  const filterCallback = props.filter ?? (() => (true));

  const options: SelectOption[] =
    userGroups
      .filter(filterCallback)
      .map(group => ({
        value: String(group.id),
        label: group.name,
      }));

  return (
    <SingleSelectControl
      name={ `ownerId${ label ? label.replace(/\s/g, '') : '' }` }
      label={ label || 'Owner' }
      isClearable={ props.isClearable }
      isDisabled={ false }
      { ...props }

      isLoading={ isLoading }
      values={ options }
      selected={ selected !== undefined ? String(selected) : undefined }
      changeHandler={ (value?: string) => (props.changeHandler(value !== undefined ? Number(value) : undefined)) }
    />
  );
};

export default Component;
