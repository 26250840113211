import { useQuery } from 'react-query';
import { ConnectivityChartData, ConnectivityHistoryFilters } from 'models/connectivity';
import { fetchZonesOverallHistory } from 'clients/connectivity';
import { useFormActionNotifier } from 'hooks/form';
import { getResponse } from 'utils/clients';


export const useConnectivityOverallHistory = (params: ConnectivityHistoryFilters, enabled = true) => {
  const notifier = useFormActionNotifier();
  const queryResult = useQuery({
    queryKey: ['connectivity/overall/history', params],
    queryFn: async () => {
      const res = await fetchZonesOverallHistory(params, 40);
      return getResponse<ConnectivityChartData[]>(res).data;
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: enabled,
    onError: e => {
      notifier.notifyError(`Error while fetching Connectivity Statistic: ${ (e as Error).message }`);
    },
  });

  return {
    isLoading: queryResult.isLoading,
    data: queryResult.data ?? [],
  };
};