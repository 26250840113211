import React from 'react';
import { getProjectPath } from 'routing/paths';
import { useProjectsDictionarySelector } from 'hooks/user-managment';

// components
import Link from '@mui/material/Link';
import { CustomLink } from 'components/Links';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface Props {
  projectId?: number;
  name?: string;
  innerProps?: TypographyProps;
  newWindow ?: boolean
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

export const ProjectLink = ({ projectId, name, innerProps, newWindow }: Props): JSX.Element => {
  const { projects } = useProjectsDictionarySelector(projectId !== undefined && name === undefined);
  name = name ?? projects.find(p => p.id === projectId)?.name;

  if (!projectId) {
    const TypoProps = { ...defaultProps, ...innerProps };
    return (
      <Typography { ...TypoProps }>-</Typography>
    );
  }

  return (
    <Link
      component={ CustomLink }
      color="secondary"
      to={ getProjectPath(projectId) }
      withReturnUrl
      target={ newWindow ? '_blank' : undefined }
    >
      { name ?? projectId }
    </Link>
  );
};
