import { useQuery } from 'react-query';
import { useFormActionNotifier } from 'hooks/form';
import { configureQueryClient, getRelatedCacheKeys } from 'utils/react-query';
import { sortStrings } from 'helpers';
import { fetchGroups } from 'clients/device-management';
import { Group, GroupsFilters } from 'models/device-management';
import { getResponseData } from 'utils/clients';

interface GetGroupOptions {
  id: number
}

export function useGroup({ id }: GetGroupOptions) {
  const queryClient = configureQueryClient();
  const result = useQuery({
    queryKey: ['deviceManagement/groups', id],
    queryFn: async () => getResponseData(await fetchGroups({ groups: [id] })),
    initialData: () => {
      // check current group in queryClient cache
      const keys = getRelatedCacheKeys(queryClient, 'deviceManagement/groups');
      const allData = keys.map(k => queryClient.getQueryData<Group[]>(k)).filter(d => !!d).flat() as Group[];
      const data = [...new Map(allData.map(g => [g.id, g])).values()];
      const groupFromCache = data.find(g => g.id === id);
      return groupFromCache ? [groupFromCache] : undefined;
    },
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  return {
    group: result.data && result.data[0],
    isLoading: result.isLoading,
  };
}

interface GroupsQueryResult {
  isLoading: boolean;
  groups: Group[];
  group?: Group;
  refetch: () => void;
}

interface GetGroupsHookOptions {
  params?: GroupsFilters;
  enabled?: boolean;
}

export function useGroups({ params = {}, enabled }: GetGroupsHookOptions): GroupsQueryResult {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['deviceManagement/groups', params],
    queryFn: async () => getResponseData(await fetchGroups(params)),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 5 * 60 * 1000, // 5 min,
    staleTime: 5 * 60 * 1000, // 5 min,
    refetchInterval: 5 * 60 * 1000, // 5 min,
    onError: e => {
      notifyError(`Error while fetching groups: ${ (e as Error).message }`);
    },
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    groups: queryResult.data ? queryResult.data.sort((a, b) => sortStrings(a?.name, b?.name)) : [],
    refetch: queryResult.refetch,
  };
}
